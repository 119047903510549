<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                vid="name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                >
                  <b-form-input
                    v-model="form.name"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                >
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- mobile -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Mobile"
                vid="mobile"
                rules="required|numeric|min:10|max:14"
              >
                <b-form-group
                  label="Mobile"
                >
                  <b-form-input
                    v-model="form.mobile"
                    type="number"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- password -->
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                vid="password"
                :rules="isEdit?'':'required|min:6'"
              >
                <b-form-group
                  label="Password"
                >
                  <b-form-input
                    v-model="form.password"
                    type="password"
                    placeholder="Password"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Confirm Password"
                vid="confirm_password"
                :rules="isEdit?'':'required|min:6|confirmed:password'"
              >
                <b-form-group
                  label="Confirm Password"
                >
                  <b-form-input
                    v-model="form.password_confirmation"
                    type="password"
                    placeholder="Confirm Password"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="roles"
                name="Roles"
                rules="required"
              >
                <b-form-group
                  label="Roles"
                >
                  <v-select
                    v-model="form.roles"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="roles"
                    :reduce="item => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      roles: [],
      form: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        mobile: null,
        status: 'active',
        roles: null,
      },
    }
  },
  created() {
    this.getRoles()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getRoles() {
      this.axios.get('/roles/list')
        .then(res => {
          this.roles = res.data.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            status: data.status,
            roles: data.roles_ids,
          }
        })
    },
  },

}
</script>
