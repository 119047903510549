var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Name",
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Name",
                  "autocomplete": "off"
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "vid": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Email"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "email",
                  "placeholder": "Email",
                  "autocomplete": "off"
                },
                model: {
                  value: _vm.form.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "email", $$v);
                  },
                  expression: "form.email"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Mobile",
            "vid": "mobile",
            "rules": "required|numeric|min:10|max:14"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Mobile"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "placeholder": "Mobile"
                },
                model: {
                  value: _vm.form.mobile,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "mobile", $$v);
                  },
                  expression: "form.mobile"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Password",
            "vid": "password",
            "rules": _vm.isEdit ? '' : 'required|min:6'
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Password"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "password",
                  "placeholder": "Password",
                  "autocomplete": "new-password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "password", $$v);
                  },
                  expression: "form.password"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Confirm Password",
            "vid": "confirm_password",
            "rules": _vm.isEdit ? '' : 'required|min:6|confirmed:password'
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Confirm Password"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "password",
                  "placeholder": "Confirm Password",
                  "autocomplete": "new-password"
                },
                model: {
                  value: _vm.form.password_confirmation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "password_confirmation", $$v);
                  },
                  expression: "form.password_confirmation"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "vid": "roles",
            "name": "Roles",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Roles"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "multiple": "",
                  "label": "name",
                  "options": _vm.roles,
                  "reduce": function reduce(item) {
                    return item.id;
                  }
                },
                model: {
                  value: _vm.form.roles,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "roles", $$v);
                  },
                  expression: "form.roles"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }